// Generated automatically, please don't write here

window._env_ = {
  APICONFIG_TENANT: "https://login.microsoftonline.com/7788edaf-0346-4068-9d79-c868aed15b3d",
  APICONFIG_CLIENT_ID: "a24c0fa2-cb2f-4d05-a5cf-d71ceef3dbc7",
  APICONFIG_BASEPATH: "/apiconfig/api/v1",
  APICONFIG_REDIRECT_URI: "https://config.platform.pagopa.it/",
  APICONFIG_SCOPES: "api://pagopa-p-apiconfig-be/access-apiconfig-be",
  APICONFIG_HOST: "https://api.platform.pagopa.it",
  APICONFIG_BASEPATH_ALT: "",
};
